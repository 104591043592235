@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding-top: 4rem;
  margin: 0;
  color: #333;
  --type-first: "Helvetica", Arial, sans-serif;
  --type-second: "Spectral", georgia;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  margin-top: 2rem;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: #fb1;
  position: absolute;
  bottom: 5px;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}
.animeLeft {
  opacity: 0;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
}

@-webkit-keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh + 10rem);
}

.AppBody {
  flex: 1 1;
}

.VictoryContainer {
  height: auto !important;
  height: initial !important;
}

.Header_header__2Knh_ {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #fff;
  top: 0;
}

.Header_nav__1dhfH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.Header_logo__188yt {
  padding: 0.5rem 0;
}

.Header_login__Pmw1_ {
  color: #333;
  display: flex;
  align-items: center;
}

.Header_login__Pmw1_::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 17px;
  background: url(/static/media/usuario.dc442992.svg) no-repeat center center;
  margin-left: 0.5rem;
  position: relative;
  top: -1px;
}

.Footer_footer__2TOeS {
  background-color: #fb1;
  padding: 3rem 1rem 0 1rem;
  height: 10rem;
  text-align: center;
  color: #764701;
}

.Footer_footer__2TOeS p {
  margin-top: 1rem;
}

.FeedModal_modal__23a0d {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 1000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
}

@media (max-width: 40rem) {
  .FeedModal_modal__23a0d {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}

.Loading_wrapper__1y8IF {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  z-index: 1000;
}

.Loading_loading__2MqA3 {
  margin: auto;
  width: 80px;
  height: 80px;
  display: flex;
  padding-left: 5px;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Image_wrapper__WNVJt {
  display: grid;
}

.Image_img__bbuGg {
  display: block;
  max-width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.2s;
}

.Image_skeleton__2iCEB {
  grid-area: 1/1;
  height: 100%;
  background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
  background-color: #eee;
  background-size: 200%;
  -webkit-animation: Image_skeleton__2iCEB 1.5s infinite;
          animation: Image_skeleton__2iCEB 1.5s infinite;
}

@-webkit-keyframes Image_skeleton__2iCEB {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

@keyframes Image_skeleton__2iCEB {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

.PhotoCommentsForm_form__1izCF {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: stretch;
  margin: 1rem;
}

.PhotoCommentsForm_single__3ORYG.PhotoCommentsForm_form__1izCF {
  margin: 1rem 0;
}

.PhotoCommentsForm_textarea__26d2V {
  display: block;
  width: 100%;
  border: none;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: #eee;
  transition: 0.2s;
}

.PhotoCommentsForm_textarea__26d2V::focus,
.PhotoCommentsForm_textarea__26d2V::hover {
  outline: none;
  border-color: #fb1;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px #fea;
}

.PhotoCommentsForm_button__6ZS7O {
  border: none;
  cursor: pointer;
  color: #333333;
  background-color: transparent;
  font-size: 1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.PhotoCommentsForm_button__6ZS7O:focus,
.PhotoCommentsForm_button__6ZS7O:hover {
  outline: none;
}

.PhotoCommentsForm_button__6ZS7O:focus svg path,
.PhotoCommentsForm_button__6ZS7O:hover svg path {
  fill: #fea;
  stroke: #fb1;
}

.PhotoCommentsForm_button__6ZS7O:focus svg g,
.PhotoCommentsForm_button__6ZS7O:hover svg g {
  -webkit-animation: PhotoCommentsForm_latir__2yYF5 0.6s infinite;
          animation: PhotoCommentsForm_latir__2yYF5 0.6s infinite;
}

@-webkit-keyframes PhotoCommentsForm_latir__2yYF5 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes PhotoCommentsForm_latir__2yYF5 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.PhotoComments_comments__1vkzl {
  overflow-y: auto;
  word-break: break-word;
  padding: 0 2rem;
}

.PhotoComments_comments__1vkzl li {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.PhotoComments_single__170t6.PhotoComments_comments__1vkzl {
  padding: 0px;
}

.PhotoContent_photo__1jb71 {
  margin: auto;
  height: 36rem;
  border-radius: 0.2rem;
  background-color: white;
  display: grid;
  grid-template-columns: 36rem 20rem;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-animation: PhotoContent_scaleUp__3Z3kA 0.3s forwards;
          animation: PhotoContent_scaleUp__3Z3kA 0.3s forwards;
}

.PhotoContent_single__3EXHg.PhotoContent_photo__1jb71 {
  grid-template-columns: 1fr;
  height: auto;
}

@-webkit-keyframes PhotoContent_scaleUp__3Z3kA {
  to {
    opacity: 1;
    opacity: initial;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes PhotoContent_scaleUp__3Z3kA {
  to {
    opacity: 1;
    opacity: initial;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}
.PhotoContent_details__1Mc5f {
  padding: 2rem 2rem 0 2rem;
}

.PhotoContent_single__3EXHg .PhotoContent_details__1Mc5f {
  padding: 1rem 0 0 0;
}

.PhotoContent_img__1yznZ {
  grid-row: 1/4;
}

.PhotoContent_single__3EXHg .PhotoContent_img__1yznZ {
  grid-row: 1;
  border-radius: 0.4rem;
  overflow: hidden;
}

@media (max-width: 64rem) {
  .PhotoContent_photo__1jb71 {
    height: auto;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
    grid-template-columns: minmax(20rem, 40rem);
  }
  .PhotoContent_img__1yznZ {
    grid-row: 1;
  }
}

.PhotoContent_author__1aZI3 {
  opacity: 0.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PhotoContent_author__1aZI3 a:hover {
  text-decoration: underline;
}

.PhotoContent_visualizacoes__76N4t::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/visualizacao-black.957befc3.svg);
}

.PhotoContent_attributes__2zK1K {
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PhotoContent_attributes__2zK1K li {
  margin-right: 2rem;
}

.PhotoContent_attributes__2zK1K li::before {
  content: "";
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  width: 2px;
  background-color: #333333;
  margin-top: 5px;
}

.PhotoDelete_delete__2_NHn {
  background-color: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.PhotoDelete_delete__2_NHn:focus,
.PhotoDelete_delete__2_NHn:hover {
  outline: none;
  background-color: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

.FeedPhotosItem_photo__3BbdJ:nth-child(2) {
  grid-column: 2/4;
  grid-row: span 2;
}

@media (max-width: 40rem) {
  .FeedPhotosItem_photo__3BbdJ:nth-child(2) {
    grid-column: initial;
    grid-row: initial;
  }
}

.FeedPhotosItem_photo__3BbdJ {
  display: grid;
  border-radius: 0.2rem;
  overflow: hidden;
  cursor: pointer;
}

/* .photo img {
  grid-area: 1/1;
} */
.FeedPhotosItem_photo__3BbdJ > div {
  grid-area: 1/1;
}

.FeedPhotosItem_photo__3BbdJ .FeedPhotosItem_visualizacao__2bNIn {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  grid-area: 1/1;
  display: none;
}

.FeedPhotosItem_visualizacao__2bNIn::before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 0.25rem;
  background: url(/static/media/visualizacao.4ce15ed8.svg) no-repeat;
}

.FeedPhotosItem_photo__3BbdJ:hover .FeedPhotosItem_visualizacao__2bNIn {
  display: flex;
}

.FeedPhotos_feed__3DPFB {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 40rem) {
  .FeedPhotos_feed__3DPFB {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Button_button__2eYLv {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background-color: #fb1;
  color: #764701;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
}

.Button_button__2eYLv:hover,
.Button_button__2eYLv:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px #fb1;
}

.Button_button__2eYLv:disabled {
  opacity: 0.5;
  cursor: wait;
}

.Input_wrapper__3FxuN {
  margin-bottom: 1rem;
}

.Input_input__3l7Hi {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.Input_input__3l7Hi:hover,
.Input_input__3l7Hi:focus {
  outline: none;
  border-color: #fb1;
  background-color: #fff;
  box-shadow: 0 0 0 3px #fea;
}

.Input_label__2vrTz {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__jDALL {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.LoginForm_form__3hbBG {
  margin-bottom: 2rem;
}

.LoginForm_perdeu__2PjTB {
  display: inline-block;
  color: #666666;
  padding: 0.5rem 0;
  line-height: 1;
}

.LoginForm_perdeu__2PjTB::after {
  content: "";
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.LoginForm_cadastro__17aju {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.LoginForm_cadastro__17aju p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.LoginForm_subtitle__13UUC {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 2rem;
}

.LoginForm_subtitle__13UUC::after {
  content: "";
  display: block;
  background-color: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
}

.Login_login__2JQ-v {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  grid-gap: 2rem;
  gap: 2rem;
}

.Login_login__2JQ-v::before {
  display: block;
  content: "";
  background: url(/static/media/login.824d7d85.jpg) no-repeat center center;
  background-size: cover;
}

.Login_forms__Z_aM_ {
  max-width: 30rem;
  padding: 1rem;
  margin-top: 20vh;
}

@media (max-width: 40rem) {
  .Login_login__2JQ-v {
    grid-template-columns: 1fr;
  }
  .Login_login__2JQ-v::before {
    display: none;
  }
  .Login_forms__Z_aM_ {
    max-width: 100%;
  }
}

.UserHeaderNav_nav__1hUpT {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.UserHeaderNav_nav__1hUpT a,
.UserHeaderNav_nav__1hUpT button {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
}

.UserHeaderNav_nav__1hUpT a:hover,
.UserHeaderNav_nav__1hUpT a:focus,
.UserHeaderNav_nav__1hUpT button:hover,
.UserHeaderNav_nav__1hUpT button:focus {
  background-color: #fff;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
  outline: none;
}

.UserHeaderNav_nav__1hUpT a.active {
  background: white;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
}

.UserHeaderNav_nav__1hUpT a.active svg > * {
  fill: #fb1;
}

.UserHeaderNav_mobileButton__3ZWT6 {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
}

.UserHeaderNav_mobileButton__3ZWT6::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 2px;
  border-radius: 2px;
  background-color: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.UserHeaderNav_mobileButton__3ZWT6:hover,
.UserHeaderNav_mobileButton__3ZWT6:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
  color: #fb1;
}

.UserHeaderNav_mobileButtonActive__17eV-::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.UserHeaderNav_navMobile__1qKpR {
  display: block;
  position: absolute;
  top: 70px;
  right: 0;
  padding: 0 1rem;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}

.UserHeaderNav_navMobileActive__2TfgG {
  transition: 0.3s;
  -webkit-transform: none;
          transform: none;
  -webkit-transform: initial;
          transform: initial;
  opacity: 1;
  pointer-events: initial;
  z-index: 100;
}

.UserHeaderNav_navMobile__1qKpR a,
.UserHeaderNav_navMobile__1qKpR button {
  display: flex;
  align-items: center;
  background-color: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
  cursor: pointer;
}

.UserHeaderNav_navMobile__1qKpR a:hover svg > *,
.UserHeaderNav_navMobile__1qKpR button:hover > * {
  fill: #fb1;
}
.UserHeaderNav_navMobile__1qKpR button {
  border-bottom: none;
}

.UserHeaderNav_navMobile__1qKpR svg {
  margin-right: 0.5rem;
}

.UserHeader_header__1JitR {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
}

.UserPhotoPost_photoPost__9tKdD {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

@media (max-width: 40rem) {
  .UserPhotoPost_photoPost__9tKdD {
    grid-template-columns: 1fr;
  }
}

.UserPhotoPost_file__1CmMn {
  margin-bottom: 1rem;
}

.UserPhotoPost_preview__2WZRY {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.UserPhotoPost_preview__2WZRY::after {
  content: "";
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

