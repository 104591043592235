.delete {
  background-color: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.delete:focus,
.delete:hover {
  outline: none;
  background-color: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}
